<template>
  <div>
    <select-pos-model v-model="filters.id_pos" :label="$t('labels.pos')" dense outlined clearable hide-details
      @change="onUpdate"></select-pos-model>
  </div>
</template>

<script>

export default {
  name: "PickupStep1",
  props: {
    selected: {
      type: Object,
      default: () => { }
    }
  },
  data: () => ({
    warehouses: [],
    filters: {},
  }),
  created() {
    this.filters = { ...this.selected }
  },
  methods: {
    onUpdate() {
      this.$emit('onUpdate', this.filters)
    }
  },
  mounted() {

  }
}
</script>

<style scoped></style>
